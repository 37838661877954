html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input, textarea, button {
  font-family: inherit;
  font-size: inherit;
}

body * {
  box-sizing: border-box;
}
