html {
  font-family: Roboto Slab, serif;
  font-size: var(--font-size-default);
  font-weight: normal;
  line-height: 24px;
}

p {
  margin: 0 0 var(--spacing-lg) 0;
}

h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.3;
  margin: 0 0 var(--spacing-lg) 0;
  background: var(--color-gradient-gold);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  text-align: center;
  font-size: var(--font-size-xl);
  line-height: 1.2;
  font-weight: 900;
  margin-top: 0;
  // = glob-yellow-text
  background: var(--color-gradient-gold);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  margin-bottom: var(--spacing-lg);
}

a, a:link, a:visited {
  font-weight: 600;
  text-decoration: none;
  background: var(--color-gradient-gold);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &.glob-link-with-arrow {
    position: relative;
    margin-right: 32px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: calc((100% - 16px) / 2);
      right: -30px;
      width: 22px;
      height: 16px;
      background-image: url("/assets/icons/arrow_right_alt.svg");
    }
  }
}
