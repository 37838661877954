
.glob-input {
  border-radius: var(--radius-md);
  border: 0;
  padding: 12px 18px;
  letter-spacing: 0.03em;
  height: 50px;
  box-shadow: var(--box-shadow-grey-inset);
  background-color: var(--color-background-brown);
  font-size: 16px;
  font-weight: 900;
  line-height: 16px;
  text-align: left;
  outline: 0;
  color: var(--color-white);

  &[type=text],
  &[type=password],
  &[type=email] {
    text-align: center;
  }

  &::placeholder {
    color: var(--color-white);
    opacity: 0.4;
  }

  &:focus,
  &:focus-visible,
  &:active {
    box-shadow: none;
  }

  &--full-width {
    width: 100%;
  }

  &[readonly] {
    opacity: var(--opacity-disabled);
    font-style: italic;
  }
}

textarea.glob-input {
  height: unset;
  font-size: inherit;
}
