@keyframes page-enter {
  from {
    transform: translateX(100vw);
  }
}

@keyframes page-exit {
  to {
    transform: translateX(0);
  }
}

@keyframes modal-page-enter {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes modal-page-exit {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateY(100vh);
  }
}

@keyframes alert-enter {
  from {
    transform: translateY(0vh);
    opacity: 0;
  }

  to {
    transform: translateY(-20vh);
    opacity: 1;
  }
}

@keyframes alert-exit {
  from {
    transform: translateY(-20vh);
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 0;
  }
}
