.glob-center {
  text-align: center;
}

.glob-font-l {
  font-size: var(--font-size-l);
}

.glob-font-12 {
  font-size: 12px;
}

.glob-font-13 {
  font-size: 13px;
}

.glob-font-16 {
  font-size: 16px;
  line-height: 16px;
}

.glob-font-18 {
  font-size: 18px;
}

.glob-font-24 {
  font-size: 24px;
  line-height: 120%;
}

.glob-font-w900 {
  font-weight: 900;
}

.glob-font-w500 {
  font-weight: 500;
}

.glob-font-w600 {
  font-weight: 600;
}

.glob-font-lineh-100 {
  line-height: 1;
}

.glob-font-lineh-120 {
  line-height: 1.2;
}

.glob-font-lineh-16 {
  line-height: 16px;
}

.glob-flex {
  display: flex;
  align-items: center;
}
.glob-flex--gap {
  gap: var(--spacing-lg);
}

.glob-flex--gap-2 {
  gap: var(--spacing-xs);
}

.glob-flex--gap-4 {
  gap: var(--spacing-xs);
}

.glob-flex--gap-6 {
  gap: var(--spacing-sm);
}

.glob-flex--gap-8 {
  gap: var(--spacing-md);
}

.glob-flex--top {
  align-items: flex-start;
}

.glob-flex--space-between {
  justify-content: space-between;
}

.glob-flex--column {
  flex-direction: column;
}

.glob-flex--center {
  justify-content: center;
}

.glob-margin-top {
  margin-top: var(--spacing-default);
}

.glob-margin-top-6 {
  margin-top: var(--spacing-sm);
}

.glob-margin-top-8 {
  margin-top: var(--spacing-md);
}

.glob-margin-top-4 {
  margin-top: var(--spacing-xs);
}

.glob-margin-top-m {
  margin-top: var(--spacing-m);
}

.glob-margin-top-l {
  margin-top: var(--spacing-l);
}

.glob-margin-top-xxl {
  margin-top: var(--spacing-xxl);
}

.glob-margin-top-xxxl {
  margin-top: var(--spacing-xxxl);
}

.glob-margin-bottom {
  margin-bottom: var(--spacing-default);
}

.glob-margin-bottom-m {
  margin-bottom: var(--spacing-m);
}

.glob-margin-bottom-l {
  margin-bottom: var(--spacing-l);
}

.glob-margin-bottom-8 {
  margin-bottom: var(--spacing-md);
}

.glob-margin-bottom-s {
  margin-bottom: var(--spacing-lg);
}

.glob-no-margin-bottom {
  margin-bottom: 0;
}

.glob-no-margin-top {
  margin-top: 0;
}

.glob-margin-v-12 {
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.glob-no-margin {
  margin: 0;
}

.glob-padding-default {
  padding: var(--spacing-default);
}

.glob-padding-top {
  padding-top: var(--spacing-default);
}

.glob-no-padding-top {
  padding-top: 0;
}

.glob-no-padding-bottom {
  padding-bottom: 0;
}

.glob-padding-top-s {
  padding-top: var(--spacing-lg);
}

.glob-padding-bottom {
  padding-bottom: var(--spacing-default);
}

.glob-hpadding {
  padding-left: var(--spacing-default);
  padding-right: var(--spacing-default);
}

.glob-hpadding-l {
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
}

.glob-border-bottom {
  border-bottom: 2px solid var(--color-tundra-grey);
}

.glob-full-width {
  width: 100%;
}

.glob-badge-bg {
  box-shadow: var(--box-shadow-grey-inset);
  background: var(--color-tundra-grey);
  border-radius: var(--radius-l);
  padding: 3px 6px 4px 6px;
  border-width: 1px;
}

.glob-badge-bg-100 {
  box-shadow: var(--box-shadow-grey-inset);
  background: var(--color-tundra-grey);
  border-radius: 100px;
  padding: var(--spacing-md) var(--spacing-lg);
  border-width: 1px;
}

.glob-badge-bg-xl {
  box-shadow: var(--box-shadow-grey-inset);
  background: var(--color-tundra-grey);
  border-radius: 100px;
  padding: var(--spacing-sm);
  border-width: 1px;
}

.glob-yellow-text {
  background: var(--color-gradient-gold);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.glob-drop-shadow-text {
  filter: drop-shadow(1px 2px 0 rgba(0, 0, 0, 0.25));
}


.glob-separator {
  margin-top: 36px;
  border-top: 2px solid var(--color-transparent-grey);
  padding-top: 24px;
}

.glob-bandroom-preview-floor-color {
  fill: var(--bandroom-preview-floor-color);
}

.glob-bandroom-preview-floor-dark-color {
  fill: var(--bandroom-preview-floor-dark-color);
}

.glob-bandroom-preview-floor-light-color {
  fill: var(--bandroom-preview-floor-light-color);
}

.glob-bandroom-preview-walls-color {
  fill: var(--bandroom-preview-walls-color);
}

.glob-bandroom-preview-walls-dark-color {
  fill: var(--bandroom-preview-walls-dark-color);
}

.glob-bandroom-preview-walls-light-color {
  fill: var(--bandroom-preview-walls-light-color);
}

.glob-bandroom-floor-color {
  fill: var(--bandroom-floor-color);
}

.glob-bandroom-floor-dark-color {
  fill: var(--bandroom-floor-dark-color);
}

.glob-bandroom-floor-light-color {
  fill: var(--bandroom-floor-light-color);
}

.glob-bandroom-walls-color {
  fill: var(--bandroom-walls-color);
}

.glob-bandroom-walls-dark-color {
  fill: var(--bandroom-walls-dark-color);
}

.glob-bandroom-walls-light-color {
  fill: var(--bandroom-walls-light-color);
}


// ----------- buttons -----------
button {
  cursor: pointer;
}
