:root {
  --color-white: #FFFBD9;
  --color-real-white: #fff;
  --color-background-dark: #351A20;
  --color-background-medium: #70513D;
  --color-background-brown: #4f392b;
  --color-background-light: #7E6F64;
  --color-tundra-grey: #0000004D;
  --color-brown: #614317;
  --color-kabul-brown: #5D4745;
  --color-error: #D32D27;
  --color-warning: #D76C1E;
  --color-red: #D32D27;
  --color-info: #91B5F3;
  --color-success: #388E3C;
  --color-gold: #FBCC58;
  --color-blue: #1877f2;
  --color-turquoise: #227D7F;
  --color-gradient-gold: linear-gradient(270deg, #FECC50 -1.74%, #F2E792 46.72%, #FDC057 100%);
  --color-gradient-silver: linear-gradient(270deg, #ADADAD -1.74%, #E9E9E9 46.72%, #C8C8C8 100%);
  --color-gradient-bronze: linear-gradient(270deg, #D48020 0%, #DF9550 36.98%, #CC6A1E 100%);
  --color-transparent-grey: rgba(0, 0, 0, 0.15);
  --color-transparent-dark-grey: rgba(0, 0, 0, 0.30);
  --color-dyn-background-night: #051322 url("/assets/images/night-sky-stars.svg") no-repeat top;
  --color-dyn-background-day: linear-gradient(180deg, #227D7F 0%, #FFFBD9 55vh);
  --color-dyn-background: var(--color-dyn-background-day);

  --box-shadow-grey-inset: 2px 3px 0 0 #00000040 inset;
  --box-shadow-grey-2-3: 2px 3px 0 0 #00000040;
  --box-shadow-grey: 0px -5px 10px 0 #00000040;
  --box-shadow-brown: 0px 0px 2px 0 #703B24;
  --box-shadow-grey-dark: 0 0 2px 0 #797979;
  --box-shadow-grey-silver: 0 0 2px 0 #9E9E9E;
  --shadow-glow: 0 0 2px 0 #FFFBD9;
  --flat-shadow-small: 1px 2px 0 0 rgba(0, 0, 0, 0.25);
  --flat-shadow: 2px 3px 0 0 rgba(0, 0, 0, 0.25);
  --flat-shadow-inset: 2px 3px 0 0 rgba(0, 0, 0, 0.25) inset;
  --flat-shadow-text: 1px 2px 0 rgba(0, 0, 0, 0.25);

  --spacing-default: 16px;
  --spacing-xs: 4px;
  --spacing-xxs: 2px;
  --spacing-md: 8px;
  --spacing-sm: 6px;
  --spacing-m: 24px;
  --spacing-l: 32px;
  --spacing-lg: 12px;
  --spacing-xxl: 43px;
  --spacing-xxxl: 56px;

  --radius-md: 8px;
  --radius-l: 16px;
  --radius-xl: 21px;
  --radius--xxl: 28px;

  --font-size-default: 14px;
  --font-size-l: 16px;
  --font-size-xl: 24px;

  --zindex-alert: 9999;
  --zindex-alert--content: 99;
  --zindex-loading-icon: 9998;
  --zindex-main-menu: 9;
  --zindex-app-header: 9;

  --main-menu-height: 80px;
  --search-form-height: 54px;
  --app-header-height-base: calc(50px);
  --app-header-height-base-event: calc(82px);
  --app-header-height-base-tabs: calc(85px);
  --app-header-height-base-navigation: calc(100px);
  --app-header-height-base-event-tabs: calc(135px);

  --uniform-primary-color: #000;
  --uniform-primary-color--light: color-mix(in srgb, var(--uniform-primary-color), white 15.5%);
  --uniform-secondary-color: #D42E41;
  --uniform-preview-primary-color: #000;
  --uniform-preview-primary-color--light: color-mix(in srgb, var(--uniform-preview-primary-color), white 20%);
  --uniform-preview-secondary-color: #D42E41;
  --bandroom-preview-walls-color: #CBC2B1;
  --bandroom-preview-walls-dark-color: color-mix(in srgb, var(--bandroom-preview-walls-color), black 20%);
  --bandroom-preview-walls-light-color: color-mix(in srgb, var(--bandroom-preview-walls-color), white 20%);
  --bandroom-preview-floor-color: #376E9B;
  --bandroom-preview-floor-dark-color: color-mix(in srgb, var(--bandroom-preview-floor-color), black 20%);
  --bandroom-preview-floor-light-color: color-mix(in srgb, var(--bandroom-preview-floor-color), white 20%);
  --bandroom-walls-color: #CBC2B1;
  --bandroom-walls-dark-color: color-mix(in srgb, var(--bandroom-walls-color), black 20%);
  --bandroom-walls-light-color: color-mix(in srgb, var(--bandroom-walls-color), white 20%);
  --bandroom-floor-color: #376E9B;
  --bandroom-floor-dark-color: color-mix(in srgb, var(--bandroom-floor-color), black 20%);
  --bandroom-floor-light-color: color-mix(in srgb, var(--bandroom-floor-color), white 20%);
}
