body {
  background-color: var(--color-background-dark);
  color: var(--color-white);
  position: relative;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  -webkit-tap-highlight-color: transparent;
}

.glob-page__wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  --page-wrapper-ellipse-height: 166px;
  --page-wrapper-ellipse-top: 85px;

  &--dyn-background {
    background: var(--color-dyn-background);
  }

  &:before, &:after {
    position: absolute;
    content: "";
    display: block;
    background-color: var(--color-background-medium);
    width: 100vw;
    left: 0;
    right: 0;
  }

  &:before {
    z-index: 2;
    height: var(--page-wrapper-ellipse-height);
    top: var(--page-wrapper-ellipse-top);
    width: 140vw;
    left: -20vw;
    border-radius: 50%;
  }

  &:after {
    z-index: 1;
    bottom: 0;
    top: calc(var(--page-wrapper-ellipse-top) + var(--page-wrapper-ellipse-height) / 2);
  }

  &--middle-top-64 {
    --page-wrapper-ellipse-top: 104px;
  }

  &--middle-top {
    --page-wrapper-ellipse-top: 164px;
  }

  &--middle-top2 {
    --page-wrapper-ellipse-top: 232px;
  }

  &--middle {
    --page-wrapper-ellipse-top: 40vh;
  }

  &--bottom {
    --page-wrapper-ellipse-top: 60vh;
  }

  &--no-ellipse {
    --page-wrapper-ellipse-height: 0;
  }
}

.glob-page__slim-content {
  padding: 0 var(--spacing-l);
}

.glob-page__content {
  --content-header-padding: var(--app-header-height-base);

  margin-top: var(--content-header-padding);
  position: relative;
  z-index: 2;

  // reserve place for main menu
  padding-bottom: calc(var(--main-menu-height) + 10px);

  height: calc(100vh + 10px - var(--content-header-padding));
  overflow-y: scroll;

  &--no-header {
    // the px is needed for calc()
    --app-header-height-base: 0px;
  }

  &--with-app-header-base {
    // reserve place for app-header, base: stats or navigation
    --content-header-padding: var(--app-header-height-base);
  }

  &--with-app-header-base-event {
    // reserve place for app-header with event
    --content-header-padding: var(--app-header-height-base-event);
  }

  &--with-app-header-base-tabs {
    // reserve place for app-header with tabs
    --content-header-padding: var(--app-header-height-base-tabs);
  }

  &--with-app-header-base-navigation {
    // reserve place for app-header with stats & navigation
    --content-header-padding: var(--app-header-height-base-navigation);
  }

  &--with-app-header-base-event-tabs {
    // reserve place for app-header with navigation, events and tabs
    --content-header-padding: var(--app-header-height-base-event-tabs);
  }

  &--no-scroll {
    overflow: hidden;
  }

  &--with-main-menu {
    height: calc(100vh - var(--content-header-padding) - 10px - var(--main-menu-height));
  }

  &--with-padding {
    padding: var(--spacing-default) var(--spacing-default) var(--main-menu-height) var(--spacing-default);
  }

  &--with-padding-no-menu {
    padding: var(--spacing-default);
  }

  &-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__head {
      padding: var(--spacing-default);
    }

    &__content {
      padding: var(--spacing-default);
      flex-grow: 2;
    }

    &__bottom {
    }
  }

  &-flex-scroll {
    max-height: 100vh;
  }
}

.glob-modal-content {
  overflow-y: scroll;
  padding-bottom: 0;
  height: calc(100vh - var(--content-header-padding));
}
