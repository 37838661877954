@use 'swiper/css';
@use 'swiper/css/pagination';

.swiper {
  // width: 600px;
  min-height: 620px;
  height: 100%;
}

.swiper-slide {
  padding-bottom: 40px;
}

.swiper-pagination {
  --swiper-pagination-bullet-size: 19px;
  --swiper-pagination-bullet-horizontal-gap: 12px;
  --swiper-pagination-color: #000;
  --swiper-pagination-bullet-inactive-color: #d9d9d9;
  --swiper-pagination-bullet-border-radius: 0;
  --swiper-pagination-bullet-inactive-opacity: 1;
}
