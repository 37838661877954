.glob-form-line {
  margin-bottom: var(--spacing-lg);

  &--flex {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
  }
}

.glob-input-error-msg {
  display: flex;
  justify-content: center;
  gap: 4px;
  min-height: 20px;
  margin-top: 2px;
}

.glob-label {
  display: block;
  padding-bottom: 6px;
  line-height: 1;

  &--inline {
    display: inline-block;
  }
}

